html[data-theme='payroller'] {
  --brand-900: #1c4e4e;
  --brand-800: #1f6e6c;
  --brand-700: #05a09b;
  --brand-600: #2cb2ad;
  --brand-500: #53bbb7;
  --brand-400: #7dcdca;
  --brand-300: #a6dddd;
  --brand-200: #c5e7e6;
  --brand-100: #e3f7f7;
  --brand-50: #eefcfb;
  /* Legacy */
  --primary-900: #1c4e4e;
  --primary-800: #1f6e6c;
  --primary-700: #05a09b;
  --primary-600: #2cb2ad;
  --primary-500: #53bbb7;
  --primary-400: #7dcdca;
  --primary-300: #a6dddd;
  --primary-200: #c5e7e6;
  --primary-100: #e3f7f7;
  --primary-50: #eefcfb;
  --font-sans: Inter;
}

html[data-theme='bookipay'] {
  --brand-900: #ffffff;
  --brand-800: #27137c;
  --brand-700: #371bb1;
  --brand-600: #7e3af2;
  --brand-500: #ffffff;
  --brand-400: #ffffff;
  --brand-300: #ffffff;
  --brand-200: #8f7aeb;
  --brand-100: #ffffff;
  --brand-50: #ffffff;
  /* Legacy */
  --primary-900: #ffffff;
  --primary-800: #27137c;
  --primary-700: #371bb1;
  --primary-600: #7e3af2;
  --primary-500: #ffffff;
  --primary-400: #ffffff;
  --primary-300: #ffffff;
  --primary-200: #8f7aeb;
  --primary-100: #ffffff;
  --primary-50: #ffffff;
  --font-sans: Inter;
}

html[data-theme='bookipi'] {
  --brand-900: #233876;
  --brand-800: #1e429f;
  --brand-700: #1a56db;
  --brand-600: #1c64f2;
  --brand-500: #3f83f8;
  --brand-400: #76a9fa;
  --brand-300: #a4cafe;
  --brand-200: #c3ddfd;
  --brand-100: #e1effe;
  --brand-50: #ebf5ff;
  /* Legacy */
  --primary-900: #233876;
  --primary-800: #1e429f;
  --primary-700: #1a56db;
  --primary-600: #1c64f2;
  --primary-500: #3f83f8;
  --primary-400: #76a9fa;
  --primary-300: #a4cafe;
  --primary-200: #c3ddfd;
  --primary-100: #e1effe;
  --primary-50: #ebf5ff;
  --font-sans: Inter;
}

html[data-theme='people'] {
  --brand-900: #233876;
  --brand-800: #1e429f;
  --brand-700: #1a56db;
  --brand-600: #1c64f2;
  --brand-500: #3f83f8;
  --brand-400: #76a9fa;
  --brand-300: #a4cafe;
  --brand-200: #c3ddfd;
  --brand-100: #e1effe;
  --brand-50: #ebf5ff;
  /* Legacy */
  --primary-900: #233876;
  --primary-800: #1e429f;
  --primary-700: #1a56db;
  --primary-600: #1c64f2;
  --primary-500: #3f83f8;
  --primary-400: #76a9fa;
  --primary-300: #a4cafe;
  --primary-200: #c3ddfd;
  --primary-100: #e1effe;
  --primary-50: #ebf5ff;
  --font-sans: Inter;
}

/**
  * Preflight (Minimum Effect)
  */

a,
  hr {
  color: inherit;
}

progress,
  sub,
  sup {
  vertical-align: baseline;
}

blockquote,
  body,
  dd,
  dl,
  fieldset,
  figure,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  hr,
  menu,
  ol,
  p,
  pre,
  ul {
  margin: 0;
}

*,
  :after,
  :before {
  box-sizing: border-box;
  /* border: 0 solid #e5e7eb; */
  border: 0 solid;
}

html {
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
}

body {
  line-height: inherit;
}

hr {
  height: 0;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

/* h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: inherit;
    font-weight: inherit;
  } */

a {
  text-decoration: inherit;
}

b,
  strong {
  font-weight: bolder;
}

/* code,kbd,pre,samp{font-family:ui-monospace,SFMono-Regular,Menlo,Monaco,Consolas,Liberation Mono,Courier New,monospace;font-size:1em} */

small {
  font-size: 80%;
}

sub,
  sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button,
  input,
  optgroup,
  select,
  textarea {
  font-family: inherit;
  font-weight: inherit;
  font-size: 100%;
  line-height: inherit;
  /* color: inherit; */
  margin: 0;
  padding: 0;
  border-radius: inherit;
}

button,
  select {
  text-transform: none;
}

[type='button'],
  [type='reset'],
  [type='submit'],
  button {
  -webkit-appearance: button;
  /* background-color: transparent; */
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

fieldset,
  legend {
  padding: 0;
}

menu,
  ol,
  ul {
  padding: 0;
}

textarea {
  resize: vertical;
}

/* input::-moz-placeholder,
  textarea::-moz-placeholder {
    opacity: 1;
    color: #9ca3af;
  }

  input:-ms-input-placeholder,
  textarea:-ms-input-placeholder {
    opacity: 1;
    color: #9ca3af;
  }

  input::placeholder,
  textarea::placeholder {
    opacity: 1;
    color: #9ca3af;
  } */

[role='button'],
  button {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

audio,
  canvas,
  embed,
  iframe,
  /* img, */
  object,
  svg,
  video {
  display: block;
  vertical-align: middle;
}

/* img,
  video {
    max-width: 100%;
    height: auto;
  } */

[hidden] {
  display: none;
}

/* Hide arrows on number input */

/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type='number'] {
  -moz-appearance: textfield;
}

/* Autofill background color */

input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
  --autofill-bg: #F9FAFB;
  -webkit-box-shadow: 0 0 0 60px var(--autofill-bg) inset !important;
  background-color: var(--autofill-bg) !important;
  background-clip: content-box !important;
}

*, ::before, ::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}

.bk-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.bk-pointer-events-none {
  pointer-events: none;
}

.bk-invisible {
  visibility: hidden;
}

.bk-fixed {
  position: fixed;
}

.bk-absolute {
  position: absolute;
}

.bk-relative {
  position: relative;
}

.bk-sticky {
  position: sticky;
}

.bk-inset-0 {
  inset: 0px;
}

.bk-bottom-0 {
  bottom: 0px;
}

.bk-bottom-1 {
  bottom: 0.25rem;
}

.bk-bottom-7 {
  bottom: 1.75rem;
}

.bk-left-0 {
  left: 0px;
}

.bk-left-1\/2 {
  left: 50%;
}

.bk-left-4 {
  left: 1rem;
}

.bk-left-6 {
  left: 1.5rem;
}

.bk-right-0 {
  right: 0px;
}

.bk-right-4 {
  right: 1rem;
}

.bk-right-5 {
  right: 1.25rem;
}

.bk-right-6 {
  right: 1.5rem;
}

.bk-right-\[-6px\] {
  right: -6px;
}

.bk-top-0 {
  top: 0px;
}

.bk-top-1\/2 {
  top: 50%;
}

.bk-top-3 {
  top: 0.75rem;
}

.bk-top-5 {
  top: 1.25rem;
}

.bk-top-\[-1px\] {
  top: -1px;
}

.bk-top-\[40px\] {
  top: 40px;
}

.bk-z-10 {
  z-index: 10;
}

.bk-z-30 {
  z-index: 30;
}

.bk-z-40 {
  z-index: 40;
}

.bk-z-50 {
  z-index: 50;
}

.bk-z-\[11\] {
  z-index: 11;
}

.bk-z-\[1\] {
  z-index: 1;
}

.bk-z-alert {
  z-index: 105000001;
}

.bk-z-dialog {
  z-index: 105000001;
}

.bk-z-modal {
  z-index: 105000001;
}

.bk-z-popover {
  z-index: 99999999;
}

.bk-z-portal {
  z-index: 105000002;
}

.bk-m-0 {
  margin: 0px;
}

.bk-m-auto {
  margin: auto;
}

.bk-mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.bk-my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.bk-mb-1 {
  margin-bottom: 0.25rem;
}

.bk-mb-2 {
  margin-bottom: 0.5rem;
}

.bk-mb-4 {
  margin-bottom: 1rem;
}

.bk-mb-6 {
  margin-bottom: 1.5rem;
}

.bk-mb-8 {
  margin-bottom: 2rem;
}

.bk-ml-2 {
  margin-left: 0.5rem;
}

.bk-ml-3 {
  margin-left: 0.75rem;
}

.bk-ml-\[2px\] {
  margin-left: 2px;
}

.bk-ml-auto {
  margin-left: auto;
}

.bk-mr-1 {
  margin-right: 0.25rem;
}

.bk-mr-2 {
  margin-right: 0.5rem;
}

.bk-mr-\[2px\] {
  margin-right: 2px;
}

.bk-mt-1 {
  margin-top: 0.25rem;
}

.bk-mt-2 {
  margin-top: 0.5rem;
}

.bk-mt-3 {
  margin-top: 0.75rem;
}

.bk-mt-4 {
  margin-top: 1rem;
}

.bk-mt-5 {
  margin-top: 1.25rem;
}

.bk-mt-7 {
  margin-top: 1.75rem;
}

.bk-mt-auto {
  margin-top: auto;
}

.bk-block {
  display: block;
}

.bk-inline {
  display: inline;
}

.bk-flex {
  display: flex;
}

.bk-inline-flex {
  display: inline-flex;
}

.bk-hidden {
  display: none;
}

.\!bk-h-\[33px\] {
  height: 33px !important;
}

.bk-h-0 {
  height: 0px;
}

.bk-h-0\.1 {
  height: 1px;
}

.bk-h-1 {
  height: 0.25rem;
}

.bk-h-10 {
  height: 2.5rem;
}

.bk-h-11 {
  height: 2.75rem;
}

.bk-h-12 {
  height: 3rem;
}

.bk-h-14 {
  height: 3.5rem;
}

.bk-h-16 {
  height: 4rem;
}

.bk-h-2 {
  height: 0.5rem;
}

.bk-h-20 {
  height: 5rem;
}

.bk-h-3 {
  height: 0.75rem;
}

.bk-h-4 {
  height: 1rem;
}

.bk-h-5 {
  height: 1.25rem;
}

.bk-h-6 {
  height: 1.5rem;
}

.bk-h-60 {
  height: 15rem;
}

.bk-h-64 {
  height: 16rem;
}

.bk-h-7 {
  height: 1.75rem;
}

.bk-h-8 {
  height: 2rem;
}

.bk-h-80 {
  height: 20rem;
}

.bk-h-9 {
  height: 2.25rem;
}

.bk-h-\[100px\] {
  height: 100px;
}

.bk-h-\[10px\] {
  height: 10px;
}

.bk-h-\[12px\] {
  height: 12px;
}

.bk-h-\[140px\] {
  height: 140px;
}

.bk-h-\[144px\] {
  height: 144px;
}

.bk-h-\[14px\] {
  height: 14px;
}

.bk-h-\[160px\] {
  height: 160px;
}

.bk-h-\[16px\] {
  height: 16px;
}

.bk-h-\[18px\] {
  height: 18px;
}

.bk-h-\[1px\] {
  height: 1px;
}

.bk-h-\[20px\] {
  height: 20px;
}

.bk-h-\[236px\] {
  height: 236px;
}

.bk-h-\[24px\] {
  height: 24px;
}

.bk-h-\[26px\] {
  height: 26px;
}

.bk-h-\[280px\] {
  height: 280px;
}

.bk-h-\[2px\] {
  height: 2px;
}

.bk-h-\[30px\] {
  height: 30px;
}

.bk-h-\[32px\] {
  height: 32px;
}

.bk-h-\[33px\] {
  height: 33px;
}

.bk-h-\[36px\] {
  height: 36px;
}

.bk-h-\[38px\] {
  height: 38px;
}

.bk-h-\[400px\] {
  height: 400px;
}

.bk-h-\[40px\] {
  height: 40px;
}

.bk-h-\[48px\] {
  height: 48px;
}

.bk-h-\[50px\] {
  height: 50px;
}

.bk-h-\[52px\] {
  height: 52px;
}

.bk-h-\[54px\] {
  height: 54px;
}

.bk-h-\[56px\] {
  height: 56px;
}

.bk-h-\[60px\] {
  height: 60px;
}

.bk-h-\[64px\] {
  height: 64px;
}

.bk-h-\[66px\] {
  height: 66px;
}

.bk-h-\[6px\] {
  height: 6px;
}

.bk-h-\[72px\] {
  height: 72px;
}

.bk-h-\[75px\] {
  height: 75px;
}

.bk-h-\[92px\] {
  height: 92px;
}

.bk-h-\[94px\] {
  height: 94px;
}

.bk-h-fit {
  height: -moz-fit-content;
  height: fit-content;
}

.bk-h-full {
  height: 100%;
}

.bk-h-screen {
  height: 100vh;
}

.bk-max-h-\[calc\(100\%-64px\)\] {
  max-height: calc(100% - 64px);
}

.bk-max-h-\[min\(500px\,65vh\)\] {
  max-height: min(500px,65vh);
}

.bk-max-h-full {
  max-height: 100%;
}

.\!bk-min-h-\[33px\] {
  min-height: 33px !important;
}

.bk-min-h-0 {
  min-height: 0px;
}

.bk-min-h-5 {
  min-height: 1.25rem;
}

.bk-min-h-\[20px\] {
  min-height: 20px;
}

.bk-min-h-\[36px\] {
  min-height: 36px;
}

.bk-min-h-\[40px\] {
  min-height: 40px;
}

.bk-min-h-\[48px\] {
  min-height: 48px;
}

.\!bk-w-\[65px\] {
  width: 65px !important;
}

.\!bk-w-\[95px\] {
  width: 95px !important;
}

.\!bk-w-fit {
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.bk-w-0 {
  width: 0px;
}

.bk-w-1 {
  width: 0.25rem;
}

.bk-w-10 {
  width: 2.5rem;
}

.bk-w-11 {
  width: 2.75rem;
}

.bk-w-12 {
  width: 3rem;
}

.bk-w-16 {
  width: 4rem;
}

.bk-w-2 {
  width: 0.5rem;
}

.bk-w-20 {
  width: 5rem;
}

.bk-w-3 {
  width: 0.75rem;
}

.bk-w-4 {
  width: 1rem;
}

.bk-w-44 {
  width: 11rem;
}

.bk-w-5 {
  width: 1.25rem;
}

.bk-w-56 {
  width: 14rem;
}

.bk-w-6 {
  width: 1.5rem;
}

.bk-w-60 {
  width: 15rem;
}

.bk-w-7 {
  width: 1.75rem;
}

.bk-w-8 {
  width: 2rem;
}

.bk-w-80 {
  width: 20rem;
}

.bk-w-9 {
  width: 2.25rem;
}

.bk-w-\[100px\] {
  width: 100px;
}

.bk-w-\[144px\] {
  width: 144px;
}

.bk-w-\[14px\] {
  width: 14px;
}

.bk-w-\[16px\] {
  width: 16px;
}

.bk-w-\[18px\] {
  width: 18px;
}

.bk-w-\[1px\] {
  width: 1px;
}

.bk-w-\[20px\] {
  width: 20px;
}

.bk-w-\[220px\] {
  width: 220px;
}

.bk-w-\[24px\] {
  width: 24px;
}

.bk-w-\[250px\] {
  width: 250px;
}

.bk-w-\[30px\] {
  width: 30px;
}

.bk-w-\[318px\] {
  width: 318px;
}

.bk-w-\[320px\] {
  width: 320px;
}

.bk-w-\[32px\] {
  width: 32px;
}

.bk-w-\[36px\] {
  width: 36px;
}

.bk-w-\[480px\] {
  width: 480px;
}

.bk-w-\[50px\] {
  width: 50px;
}

.bk-w-\[6px\] {
  width: 6px;
}

.bk-w-\[70px\] {
  width: 70px;
}

.bk-w-\[75px\] {
  width: 75px;
}

.bk-w-auto {
  width: auto;
}

.bk-w-fit {
  width: -moz-fit-content;
  width: fit-content;
}

.bk-w-full {
  width: 100%;
}

.bk-w-max {
  width: -moz-max-content;
  width: max-content;
}

.bk-w-px {
  width: 1px;
}

.bk-w-screen {
  width: 100vw;
}

.bk-min-w-0 {
  min-width: 0px;
}

.bk-min-w-5 {
  min-width: 1.25rem;
}

.bk-min-w-6 {
  min-width: 1.5rem;
}

.bk-min-w-\[18px\] {
  min-width: 18px;
}

.bk-min-w-\[198px\] {
  min-width: 198px;
}

.bk-min-w-\[200px\] {
  min-width: 200px;
}

.bk-min-w-\[20px\] {
  min-width: 20px;
}

.bk-min-w-\[240px\] {
  min-width: 240px;
}

.bk-min-w-\[32px\] {
  min-width: 32px;
}

.bk-min-w-\[36px\] {
  min-width: 36px;
}

.bk-min-w-full {
  min-width: 100%;
}

.bk-max-w-7xl {
  max-width: 80rem;
}

.bk-max-w-\[1280px\] {
  max-width: 1280px;
}

.bk-max-w-\[192px\] {
  max-width: 192px;
}

.bk-max-w-\[200px\] {
  max-width: 200px;
}

.bk-max-w-\[220px\] {
  max-width: 220px;
}

.bk-max-w-\[320px\] {
  max-width: 320px;
}

.bk-max-w-\[340px\] {
  max-width: 340px;
}

.bk-max-w-\[355px\] {
  max-width: 355px;
}

.bk-max-w-\[364px\] {
  max-width: 364px;
}

.bk-max-w-\[375px\] {
  max-width: 375px;
}

.bk-max-w-\[440px\] {
  max-width: 440px;
}

.bk-max-w-\[480px\] {
  max-width: 480px;
}

.bk-max-w-\[550px\] {
  max-width: 550px;
}

.bk-max-w-\[770px\] {
  max-width: 770px;
}

.bk-max-w-\[896px\] {
  max-width: 896px;
}

.bk-max-w-\[calc\(100\%-12px\)\] {
  max-width: calc(100% - 12px);
}

.bk-max-w-\[calc\(100vw-128px\)\] {
  max-width: calc(100vw - 128px);
}

.bk-max-w-\[min\(calc\(100vw_-_32px\)\,_500px\)\] {
  max-width: min(calc(100vw - 32px), 500px);
}

.bk-max-w-full {
  max-width: 100%;
}

.bk-max-w-xl {
  max-width: 36rem;
}

.bk-flex-1 {
  flex: 1 1 0%;
}

.bk-flex-shrink-0 {
  flex-shrink: 0;
}

.bk-shrink-0 {
  flex-shrink: 0;
}

.bk-flex-grow {
  flex-grow: 1;
}

.bk-grow {
  flex-grow: 1;
}

.bk-basis-\[300px\] {
  flex-basis: 300px;
}

.bk-table-auto {
  table-layout: auto;
}

.bk-table-fixed {
  table-layout: fixed;
}

.bk-border-collapse {
  border-collapse: collapse;
}

.bk-border-separate {
  border-collapse: separate;
}

.bk-border-spacing-y-1 {
  --tw-border-spacing-y: 0.25rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.-bk-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-bk-translate-x-full {
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-bk-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-bk-translate-y-full {
  --tw-translate-y: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.bk--translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.bk--translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.bk-translate-x-0 {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.bk-translate-x-full {
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.bk-translate-y-full {
  --tw-translate-y: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-bk-rotate-90 {
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.bk-rotate-0 {
  --tw-rotate: 0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.bk-rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.bk-rotate-45 {
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.bk-rotate-90 {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.bk-transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.bk-animate-fade-out {
  animation: ease-out duration-300;
}

@keyframes bk-pulse {
  50% {
    opacity: .5;
  }
}

.bk-animate-pulse {
  animation: bk-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes bk-pulse-ring {
  0%, 100% {
    outline-offset: -1px;
    outline-width: 1px;
    outline-color: var(--pulse-color, rgba(156, 163, 175, 0.5));
  }

  50% {
    outline-width: var(--pulse-size, 2px);
    outline-color: var(--pulse-color, rgba(156, 163, 175, 1));
  }
}

.bk-animate-pulse-ring {
  animation: bk-pulse-ring 1.5s cubic-bezier(0.3, 0.0, 0.8, 0.15) infinite;
}

@keyframes bk-pulse-ring-finite {
  0% {
    outline-offset: -1px;
    outline-width: 1px;
    outline-color: var(--pulse-color, rgba(156, 163, 175, 0.5));
  }

  50% {
    outline-width: var(--pulse-size, 2px);
    outline-color: var(--pulse-color, rgba(156, 163, 175, 1));
  }

  100% {
    outline-offset: -1px;
    outline-width: 1px;
    outline-color: var(--pulse-color, rgba(156, 163, 175, 0.5));
  }
}

.bk-animate-pulse-ring-finite {
  animation: bk-pulse-ring-finite var(--pulse-duration, 1.5s) cubic-bezier(0.3, 0.0, 0.8, 0.15);
}

@keyframes bk-spin {
  to {
    transform: rotate(360deg);
  }
}

.bk-animate-spin {
  animation: bk-spin 1s linear infinite;
}

.\!bk-cursor-not-allowed {
  cursor: not-allowed !important;
}

.bk-cursor-auto {
  cursor: auto;
}

.bk-cursor-default {
  cursor: default;
}

.bk-cursor-not-allowed {
  cursor: not-allowed;
}

.bk-cursor-pointer {
  cursor: pointer;
}

.bk-select-none {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.bk-list-none {
  list-style-type: none;
}

.bk-flex-row {
  flex-direction: row;
}

.bk-flex-row-reverse {
  flex-direction: row-reverse;
}

.bk-flex-col {
  flex-direction: column;
}

.bk-flex-col-reverse {
  flex-direction: column-reverse;
}

.bk-flex-wrap {
  flex-wrap: wrap;
}

.bk-items-start {
  align-items: flex-start;
}

.bk-items-end {
  align-items: flex-end;
}

.bk-items-center {
  align-items: center;
}

.bk-justify-normal {
  justify-content: normal;
}

.bk-justify-start {
  justify-content: flex-start;
}

.\!bk-justify-end {
  justify-content: flex-end !important;
}

.bk-justify-end {
  justify-content: flex-end;
}

.bk-justify-center {
  justify-content: center;
}

.bk-justify-between {
  justify-content: space-between;
}

.bk-gap-0 {
  gap: 0px;
}

.bk-gap-1 {
  gap: 0.25rem;
}

.bk-gap-2 {
  gap: 0.5rem;
}

.bk-gap-3 {
  gap: 0.75rem;
}

.bk-gap-4 {
  gap: 1rem;
}

.bk-gap-5 {
  gap: 1.25rem;
}

.bk-gap-6 {
  gap: 1.5rem;
}

.bk-gap-7 {
  gap: 1.75rem;
}

.bk-gap-8 {
  gap: 2rem;
}

.bk-gap-\[10px\] {
  gap: 10px;
}

.bk-gap-\[2px\] {
  gap: 2px;
}

.bk-gap-\[5px\] {
  gap: 5px;
}

.bk-gap-\[6px\] {
  gap: 6px;
}

.bk-gap-x-12 {
  -moz-column-gap: 3rem;
       column-gap: 3rem;
}

.bk-gap-x-2 {
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
}

.bk-gap-x-3 {
  -moz-column-gap: 0.75rem;
       column-gap: 0.75rem;
}

.bk-gap-x-4 {
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}

.bk-gap-y-0 {
  row-gap: 0px;
}

.bk-gap-y-0\.5 {
  row-gap: 2px;
}

.bk-gap-y-1 {
  row-gap: 0.25rem;
}

.bk-gap-y-2 {
  row-gap: 0.5rem;
}

.bk-gap-y-4 {
  row-gap: 1rem;
}

.bk-gap-y-5 {
  row-gap: 1.25rem;
}

.bk-space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.bk-space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
}

.bk-space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
}

.bk-space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.bk-space-y-\[2px\] > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2px * var(--tw-space-y-reverse));
}

.bk-self-end {
  align-self: flex-end;
}

.bk-self-center {
  align-self: center;
}

.bk-overflow-auto {
  overflow: auto;
}

.bk-overflow-hidden {
  overflow: hidden;
}

.bk-overflow-scroll {
  overflow: scroll;
}

.bk-overflow-y-auto {
  overflow-y: auto;
}

.bk-overflow-x-hidden {
  overflow-x: hidden;
}

.bk-overflow-y-hidden {
  overflow-y: hidden;
}

.bk-overflow-x-scroll {
  overflow-x: scroll;
}

.bk-overflow-y-scroll {
  overflow-y: scroll;
}

.bk-scroll-smooth {
  scroll-behavior: smooth;
}

.bk-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.bk-text-ellipsis {
  text-overflow: ellipsis;
}

.bk-whitespace-normal {
  white-space: normal;
}

.bk-whitespace-nowrap {
  white-space: nowrap;
}

.bk-text-nowrap {
  text-wrap: nowrap;
}

.bk-break-words {
  overflow-wrap: break-word;
}

.bk-break-keep {
  word-break: keep-all;
}

.\!bk-rounded-\[80px\] {
  border-radius: 80px !important;
}

.\!bk-rounded-full {
  border-radius: 9999px !important;
}

.bk-rounded {
  border-radius: 0.25rem;
}

.bk-rounded-2xl {
  border-radius: 1rem;
}

.bk-rounded-\[12px\] {
  border-radius: 12px;
}

.bk-rounded-\[1px\] {
  border-radius: 1px;
}

.bk-rounded-\[3px\] {
  border-radius: 3px;
}

.bk-rounded-\[4px\] {
  border-radius: 4px;
}

.bk-rounded-\[6px\] {
  border-radius: 6px;
}

.bk-rounded-\[7px\] {
  border-radius: 7px;
}

.bk-rounded-\[8px\] {
  border-radius: 8px;
}

.bk-rounded-full {
  border-radius: 9999px;
}

.bk-rounded-lg {
  border-radius: 0.5rem;
}

.bk-rounded-md {
  border-radius: 0.375rem;
}

.bk-rounded-none {
  border-radius: 0px;
}

.bk-rounded-xl {
  border-radius: 0.75rem;
}

.bk-rounded-b-xl {
  border-bottom-right-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}

.bk-rounded-t-2xl {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.bk-rounded-t-\[7px\] {
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

.bk-rounded-t-xl {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}

.bk-rounded-bl-\[12px\] {
  border-bottom-left-radius: 12px;
}

.bk-rounded-bl-full {
  border-bottom-left-radius: 9999px;
}

.bk-rounded-br-\[12px\] {
  border-bottom-right-radius: 12px;
}

.bk-rounded-br-full {
  border-bottom-right-radius: 9999px;
}

.bk-rounded-tl-\[12px\] {
  border-top-left-radius: 12px;
}

.bk-rounded-tl-full {
  border-top-left-radius: 9999px;
}

.bk-rounded-tl-lg {
  border-top-left-radius: 0.5rem;
}

.bk-rounded-tr-\[12px\] {
  border-top-right-radius: 12px;
}

.bk-rounded-tr-full {
  border-top-right-radius: 9999px;
}

.bk-rounded-tr-lg {
  border-top-right-radius: 0.5rem;
}

.bk-border {
  border-width: 1px;
}

.bk-border-2 {
  border-width: 2px;
}

.bk-border-\[1px\] {
  border-width: 1px;
}

.bk-border-\[5px\] {
  border-width: 5px;
}

.bk-border-\[6px\] {
  border-width: 6px;
}

.bk-border-x {
  border-left-width: 1px;
  border-right-width: 1px;
}

.bk-border-b {
  border-bottom-width: 1px;
}

.bk-border-l {
  border-left-width: 1px;
}

.bk-border-l-0 {
  border-left-width: 0px;
}

.bk-border-r {
  border-right-width: 1px;
}

.bk-border-t {
  border-top-width: 1px;
}

.bk-border-dashed {
  border-style: dashed;
}

.bk-border-none {
  border-style: none;
}

.bk-border-\[--tw-ring-color\] {
  border-color: var(--tw-ring-color);
}

.bk-border-blue-200 {
  --tw-border-opacity: 1;
  border-color: rgb(195 221 253 / var(--tw-border-opacity));
}

.bk-border-brand-400 {
  border-color: var(--brand-400);
}

.bk-border-brand-600 {
  border-color: var(--brand-600);
}

.bk-border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgb(243 244 246 / var(--tw-border-opacity));
}

.bk-border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.bk-border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.bk-border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity));
}

.bk-border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity));
}

.bk-border-green-100 {
  --tw-border-opacity: 1;
  border-color: rgb(222 247 236 / var(--tw-border-opacity));
}

.bk-border-primary-500 {
  border-color: var(--primary-500);
}

.bk-border-red-100 {
  --tw-border-opacity: 1;
  border-color: rgb(253 232 232 / var(--tw-border-opacity));
}

.bk-border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgb(240 82 82 / var(--tw-border-opacity));
}

.bk-border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.bk-border-yellow-100 {
  --tw-border-opacity: 1;
  border-color: rgb(253 246 178 / var(--tw-border-opacity));
}

.bk-border-yellow-600 {
  --tw-border-opacity: 1;
  border-color: rgb(159 88 10 / var(--tw-border-opacity));
}

.bk-border-r-gray-100 {
  --tw-border-opacity: 1;
  border-right-color: rgb(243 244 246 / var(--tw-border-opacity));
}

.\!bk-bg-brand-100 {
  background-color: var(--brand-100) !important;
}

.\!bk-bg-brand-700 {
  background-color: var(--brand-700) !important;
}

.\!bk-bg-transparent {
  background-color: transparent !important;
}

.\!bk-bg-white {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}

.bk-bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(4 6 7 / var(--tw-bg-opacity));
}

.bk-bg-black\/50 {
  background-color: rgb(4 6 7 / 0.5);
}

.bk-bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(225 239 254 / var(--tw-bg-opacity));
}

.bk-bg-blue-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(235 245 255 / var(--tw-bg-opacity));
}

.bk-bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(63 131 248 / var(--tw-bg-opacity));
}

.bk-bg-blue-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(26 86 219 / var(--tw-bg-opacity));
}

.bk-bg-blue-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(30 66 159 / var(--tw-bg-opacity));
}

.bk-bg-brand-100 {
  background-color: var(--brand-100);
}

.bk-bg-brand-400 {
  background-color: var(--brand-400);
}

.bk-bg-brand-50 {
  background-color: var(--brand-50);
}

.bk-bg-brand-500 {
  background-color: var(--brand-500);
}

.bk-bg-brand-600 {
  background-color: var(--brand-600);
}

.bk-bg-brand-700 {
  background-color: var(--brand-700);
}

.bk-bg-brand-800 {
  background-color: var(--brand-800);
}

.bk-bg-error-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 68 56 / var(--tw-bg-opacity));
}

.bk-bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.bk-bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bk-bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

.bk-bg-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity));
}

.bk-bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.bk-bg-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}

.bk-bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(31 42 55 / var(--tw-bg-opacity));
}

.bk-bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(17 25 40 / var(--tw-bg-opacity));
}

.bk-bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(222 247 236 / var(--tw-bg-opacity));
}

.bk-bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(188 240 218 / var(--tw-bg-opacity));
}

.bk-bg-green-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(49 196 141 / var(--tw-bg-opacity));
}

.bk-bg-green-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 250 247 / var(--tw-bg-opacity));
}

.bk-bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(14 159 110 / var(--tw-bg-opacity));
}

.bk-bg-green-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(4 108 78 / var(--tw-bg-opacity));
}

.bk-bg-green-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(3 84 63 / var(--tw-bg-opacity));
}

.bk-bg-indigo-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 237 255 / var(--tw-bg-opacity));
}

.bk-bg-indigo-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(104 117 245 / var(--tw-bg-opacity));
}

.bk-bg-modalOverlayDark {
  background-color: rgba(0, 0, 0, 0.6);
}

.bk-bg-modalOverlayLight {
  background-color: rgba(0, 0, 0, 0.2);
}

.bk-bg-orange-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 236 220 / var(--tw-bg-opacity));
}

.bk-bg-orange-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 90 31 / var(--tw-bg-opacity));
}

.bk-bg-pink-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(252 232 243 / var(--tw-bg-opacity));
}

.bk-bg-pink-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(231 70 148 / var(--tw-bg-opacity));
}

.bk-bg-primary-600 {
  background-color: var(--primary-600);
}

.bk-bg-primary-700 {
  background-color: var(--primary-700);
}

.bk-bg-purple-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(237 235 254 / var(--tw-bg-opacity));
}

.bk-bg-purple-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(144 97 249 / var(--tw-bg-opacity));
}

.bk-bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 232 232 / var(--tw-bg-opacity));
}

.bk-bg-red-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 242 242 / var(--tw-bg-opacity));
}

.bk-bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 82 82 / var(--tw-bg-opacity));
}

.bk-bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(224 36 36 / var(--tw-bg-opacity));
}

.bk-bg-red-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(200 30 30 / var(--tw-bg-opacity));
}

.bk-bg-red-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(155 28 28 / var(--tw-bg-opacity));
}

.bk-bg-transparent {
  background-color: transparent;
}

.bk-bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bk-bg-yellow-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 246 178 / var(--tw-bg-opacity));
}

.bk-bg-yellow-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 253 234 / var(--tw-bg-opacity));
}

.bk-bg-yellow-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(194 120 3 / var(--tw-bg-opacity));
}

.bk-bg-yellow-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(159 88 10 / var(--tw-bg-opacity));
}

.bk-bg-yellow-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(142 75 16 / var(--tw-bg-opacity));
}

.bk-bg-opacity-100 {
  --tw-bg-opacity: 1;
}

.bk-bg-opacity-60 {
  --tw-bg-opacity: 0.6;
}

.bk-bg-diagonal-stripe {
  background-image: linear-gradient(
          135deg,
          #f0f0f0 25%,
          #ffffff 25%,
          #ffffff 50%,
          #f0f0f0 50%,
          #f0f0f0 75%,
          #ffffff 75%,
          #ffffff 100%
        );
}

.bk-bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.bk-from-\[rgba\(0\,0\,0\,0\.06\)\] {
  --tw-gradient-from: rgba(0,0,0,0.06) var(--tw-gradient-from-position);
  --tw-gradient-to: rgba(0, 0, 0, 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.bk-to-\[rgba\(0\,0\,0\,0\)\] {
  --tw-gradient-to: rgba(0,0,0,0) var(--tw-gradient-to-position);
}

.bk-bg-cover {
  background-size: cover;
}

.bk-bg-size-base {
  background-size: 32px 32px;
}

.bk-bg-center {
  background-position: center;
}

.bk-bg-no-repeat {
  background-repeat: no-repeat;
}

.bk-fill-brand-600 {
  fill: var(--brand-600);
}

.bk-object-contain {
  -o-object-fit: contain;
     object-fit: contain;
}

.bk-object-cover {
  -o-object-fit: cover;
     object-fit: cover;
}

.bk-p-0 {
  padding: 0px;
}

.bk-p-1 {
  padding: 0.25rem;
}

.bk-p-3 {
  padding: 0.75rem;
}

.bk-p-4 {
  padding: 1rem;
}

.bk-p-5 {
  padding: 1.25rem;
}

.bk-p-6 {
  padding: 1.5rem;
}

.bk-p-8 {
  padding: 2rem;
}

.bk-p-\[10px\] {
  padding: 10px;
}

.bk-p-\[2px\] {
  padding: 2px;
}

.bk-p-\[7px\] {
  padding: 7px;
}

.bk-px-0 {
  padding-left: 0px;
  padding-right: 0px;
}

.bk-px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.bk-px-1\.5 {
  padding-left: 10px;
  padding-right: 10px;
}

.bk-px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.bk-px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.bk-px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.bk-px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.bk-px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.bk-px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.bk-px-\[10px\] {
  padding-left: 10px;
  padding-right: 10px;
}

.bk-px-\[12px\] {
  padding-left: 12px;
  padding-right: 12px;
}

.bk-px-\[14px\] {
  padding-left: 14px;
  padding-right: 14px;
}

.bk-px-\[15px\] {
  padding-left: 15px;
  padding-right: 15px;
}

.bk-px-\[26px\] {
  padding-left: 26px;
  padding-right: 26px;
}

.bk-px-\[2px\] {
  padding-left: 2px;
  padding-right: 2px;
}

.bk-px-\[8px\] {
  padding-left: 8px;
  padding-right: 8px;
}

.bk-py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}

.bk-py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.bk-py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.bk-py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.bk-py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.bk-py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.bk-py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.bk-py-7 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}

.bk-py-9 {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
}

.bk-py-\[10px\] {
  padding-top: 10px;
  padding-bottom: 10px;
}

.bk-py-\[14px\] {
  padding-top: 14px;
  padding-bottom: 14px;
}

.bk-py-\[2px\] {
  padding-top: 2px;
  padding-bottom: 2px;
}

.bk-py-\[6px\] {
  padding-top: 6px;
  padding-bottom: 6px;
}

.bk-pb-2 {
  padding-bottom: 0.5rem;
}

.bk-pb-24 {
  padding-bottom: 6rem;
}

.bk-pb-4 {
  padding-bottom: 1rem;
}

.bk-pb-5 {
  padding-bottom: 1.25rem;
}

.bk-pb-6 {
  padding-bottom: 1.5rem;
}

.bk-pb-\[75\%\] {
  padding-bottom: 75%;
}

.bk-pb-px {
  padding-bottom: 1px;
}

.bk-pl-0 {
  padding-left: 0px;
}

.bk-pl-1 {
  padding-left: 0.25rem;
}

.bk-pl-4 {
  padding-left: 1rem;
}

.bk-pl-8 {
  padding-left: 2rem;
}

.bk-pl-\[26px\] {
  padding-left: 26px;
}

.bk-pl-\[42px\] {
  padding-left: 42px;
}

.bk-pr-0 {
  padding-right: 0px;
}

.bk-pr-10 {
  padding-right: 2.5rem;
}

.bk-pr-3 {
  padding-right: 0.75rem;
}

.bk-pr-4 {
  padding-right: 1rem;
}

.bk-pr-8 {
  padding-right: 2rem;
}

.bk-pr-\[5px\] {
  padding-right: 5px;
}

.bk-pt-3 {
  padding-top: 0.75rem;
}

.bk-pt-4 {
  padding-top: 1rem;
}

.bk-pt-5 {
  padding-top: 1.25rem;
}

.bk-pt-\[8\.5px\] {
  padding-top: 8.5px;
}

.bk-pt-px {
  padding-top: 1px;
}

.bk-text-left {
  text-align: left;
}

.bk-text-center {
  text-align: center;
}

.bk-text-right {
  text-align: right;
}

.bk-font-sans {
  font-family: var(--font-sans), -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
}

.bk-text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.bk-text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.bk-text-\[11px\] {
  font-size: 11px;
}

.bk-text-base {
  font-size: 16px;
  line-height: 150%;
}

.bk-text-sm {
  font-size: 14px;
  line-height: 150%;
}

.bk-text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.bk-text-xs {
  font-size: 12px;
  line-height: 150%;
}

.bk-font-bold {
  font-weight: 700;
}

.bk-font-light {
  font-weight: 300;
}

.bk-font-medium {
  font-weight: 500;
}

.bk-font-normal {
  font-weight: 400;
}

.bk-font-semibold {
  font-weight: 600;
}

.bk-uppercase {
  text-transform: uppercase;
}

.bk-capitalize {
  text-transform: capitalize;
}

.bk-leading-4 {
  line-height: 1rem;
}

.bk-leading-6 {
  line-height: 1.5rem;
}

.bk-leading-9 {
  line-height: 2.25rem;
}

.bk-leading-\[13px\] {
  line-height: 13px;
}

.bk-leading-\[30px\] {
  line-height: 30px;
}

.bk-leading-none {
  line-height: 1;
}

.bk-leading-normal {
  line-height: 1.5;
}

.bk-leading-tight {
  line-height: 1.25;
}

.bk-tracking-\[-0\.01em\] {
  letter-spacing: -0.01em;
}

.\!bk-text-gray-300 {
  --tw-text-opacity: 1 !important;
  color: rgb(209 213 219 / var(--tw-text-opacity)) !important;
}

.\!bk-text-gray-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(107 114 128 / var(--tw-text-opacity)) !important;
}

.\!bk-text-gray-900 {
  --tw-text-opacity: 1 !important;
  color: rgb(17 25 40 / var(--tw-text-opacity)) !important;
}

.\!bk-text-white {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.bk-text-black {
  --tw-text-opacity: 1;
  color: rgb(4 6 7 / var(--tw-text-opacity));
}

.bk-text-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(63 131 248 / var(--tw-text-opacity));
}

.bk-text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(28 100 242 / var(--tw-text-opacity));
}

.bk-text-blue-700 {
  --tw-text-opacity: 1;
  color: rgb(26 86 219 / var(--tw-text-opacity));
}

.bk-text-blue-800 {
  --tw-text-opacity: 1;
  color: rgb(30 66 159 / var(--tw-text-opacity));
}

.bk-text-brand-600 {
  color: var(--brand-600);
}

.bk-text-brand-700 {
  color: var(--brand-700);
}

.bk-text-brand-800 {
  color: var(--brand-800);
}

.bk-text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

.bk-text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

.bk-text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.bk-text-gray-50 {
  --tw-text-opacity: 1;
  color: rgb(249 250 251 / var(--tw-text-opacity));
}

.bk-text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.bk-text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.bk-text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.bk-text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 42 55 / var(--tw-text-opacity));
}

.bk-text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 25 40 / var(--tw-text-opacity));
}

.bk-text-green-400 {
  --tw-text-opacity: 1;
  color: rgb(49 196 141 / var(--tw-text-opacity));
}

.bk-text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(14 159 110 / var(--tw-text-opacity));
}

.bk-text-green-600 {
  --tw-text-opacity: 1;
  color: rgb(5 122 85 / var(--tw-text-opacity));
}

.bk-text-green-700 {
  --tw-text-opacity: 1;
  color: rgb(4 108 78 / var(--tw-text-opacity));
}

.bk-text-green-800 {
  --tw-text-opacity: 1;
  color: rgb(3 84 63 / var(--tw-text-opacity));
}

.bk-text-indigo-500 {
  --tw-text-opacity: 1;
  color: rgb(104 117 245 / var(--tw-text-opacity));
}

.bk-text-indigo-800 {
  --tw-text-opacity: 1;
  color: rgb(66 56 157 / var(--tw-text-opacity));
}

.bk-text-orange-400 {
  --tw-text-opacity: 1;
  color: rgb(255 138 76 / var(--tw-text-opacity));
}

.bk-text-orange-800 {
  --tw-text-opacity: 1;
  color: rgb(138 44 13 / var(--tw-text-opacity));
}

.bk-text-pink-500 {
  --tw-text-opacity: 1;
  color: rgb(231 70 148 / var(--tw-text-opacity));
}

.bk-text-pink-800 {
  --tw-text-opacity: 1;
  color: rgb(153 21 75 / var(--tw-text-opacity));
}

.bk-text-primary-700 {
  color: var(--primary-700);
}

.bk-text-purple-500 {
  --tw-text-opacity: 1;
  color: rgb(144 97 249 / var(--tw-text-opacity));
}

.bk-text-purple-800 {
  --tw-text-opacity: 1;
  color: rgb(85 33 181 / var(--tw-text-opacity));
}

.bk-text-red-300 {
  --tw-text-opacity: 1;
  color: rgb(248 180 180 / var(--tw-text-opacity));
}

.bk-text-red-400 {
  --tw-text-opacity: 1;
  color: rgb(249 128 128 / var(--tw-text-opacity));
}

.bk-text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(240 82 82 / var(--tw-text-opacity));
}

.bk-text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(224 36 36 / var(--tw-text-opacity));
}

.bk-text-red-700 {
  --tw-text-opacity: 1;
  color: rgb(200 30 30 / var(--tw-text-opacity));
}

.bk-text-red-800 {
  --tw-text-opacity: 1;
  color: rgb(155 28 28 / var(--tw-text-opacity));
}

.bk-text-success-500 {
  --tw-text-opacity: 1;
  color: rgb(18 183 106 / var(--tw-text-opacity));
}

.bk-text-warning-300 {
  --tw-text-opacity: 1;
  color: rgb(254 200 75 / var(--tw-text-opacity));
}

.bk-text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.bk-text-yellow-400 {
  --tw-text-opacity: 1;
  color: rgb(227 160 8 / var(--tw-text-opacity));
}

.bk-text-yellow-600 {
  --tw-text-opacity: 1;
  color: rgb(159 88 10 / var(--tw-text-opacity));
}

.bk-text-yellow-700 {
  --tw-text-opacity: 1;
  color: rgb(142 75 16 / var(--tw-text-opacity));
}

.bk-text-yellow-800 {
  --tw-text-opacity: 1;
  color: rgb(114 59 19 / var(--tw-text-opacity));
}

.bk-placeholder-gray-300::-moz-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(209 213 219 / var(--tw-placeholder-opacity));
}

.bk-placeholder-gray-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(209 213 219 / var(--tw-placeholder-opacity));
}

.bk-placeholder-gray-500::-moz-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(107 114 128 / var(--tw-placeholder-opacity));
}

.bk-placeholder-gray-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(107 114 128 / var(--tw-placeholder-opacity));
}

.bk-placeholder-red-400::-moz-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(249 128 128 / var(--tw-placeholder-opacity));
}

.bk-placeholder-red-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(249 128 128 / var(--tw-placeholder-opacity));
}

.bk-opacity-0 {
  opacity: 0;
}

.bk-opacity-100 {
  opacity: 1;
}

.bk-opacity-20 {
  opacity: 0.2;
}

.bk-opacity-30 {
  opacity: 0.3;
}

.bk-opacity-50 {
  opacity: 0.5;
}

.bk-opacity-80 {
  opacity: 0.8;
}

.bk-shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.bk-shadow-\[0px_1px_2px_0px_rgba\(16\,24\,40\,0\.05\)\] {
  --tw-shadow: 0px 1px 2px 0px rgba(16,24,40,0.05);
  --tw-shadow-colored: 0px 1px 2px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.bk-shadow-\[inset_0_0_0_1px\] {
  --tw-shadow: inset 0 0 0 1px;
  --tw-shadow-colored: inset 0 0 0 1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.bk-shadow-\[inset_0px_1px_0px_0px\] {
  --tw-shadow: inset 0px 1px 0px 0px;
  --tw-shadow-colored: inset 0px 1px 0px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.bk-shadow-lg {
  --tw-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10);
  --tw-shadow-colored: 0px 4px 6px 0px var(--tw-shadow-color), 0px 10px 15px -3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.bk-shadow-lg-modal {
  --tw-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10);
  --tw-shadow-colored: 0px 4px 6px 0px var(--tw-shadow-color), 0px 10px 15px -3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.bk-shadow-md {
  --tw-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.05), 0px 4px 6px -1px rgba(0, 0, 0, 0.10);
  --tw-shadow-colored: 0px 2px 4px -2px var(--tw-shadow-color), 0px 4px 6px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.bk-shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.bk-shadow-gray-200 {
  --tw-shadow-color: #E5E7EB;
  --tw-shadow: var(--tw-shadow-colored);
}

.bk-outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.bk-outline {
  outline-style: solid;
}

.bk-outline-1 {
  outline-width: 1px;
}

.-bk-outline-offset-1 {
  outline-offset: -1px;
}

.bk-outline-offset-1 {
  outline-offset: 1px;
}

.bk-outline-offset-\[-1px\] {
  outline-offset: -1px;
}

.bk-outline-gray-200 {
  outline-color: #E5E7EB;
}

.bk-outline-gray-300 {
  outline-color: #D1D5DB;
}

.bk-outline-gray-600 {
  outline-color: #4B5563;
}

.bk-outline-red-500 {
  outline-color: #F05252;
}

.bk-ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.bk-ring-2 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.bk-ring-gray-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(243 244 246 / var(--tw-ring-opacity));
}

.bk-ring-gray-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(229 231 235 / var(--tw-ring-opacity));
}

.bk-ring-offset-2 {
  --tw-ring-offset-width: 2px;
}

.bk-ring-offset-white {
  --tw-ring-offset-color: #fff;
}

.bk-transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.bk-transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.bk-transition-opacity {
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.bk-transition-transform {
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.bk-delay-\[1ms\] {
  transition-delay: 1ms;
}

.bk-duration-200 {
  transition-duration: 200ms;
}

.bk-duration-300 {
  transition-duration: 300ms;
}

.bk-duration-75 {
  transition-duration: 75ms;
}

.bk-ease-linear {
  transition-timing-function: linear;
}

.bk-hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.bk-hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.error-autofill {
  --autofill-bg: #FDF2F2 !important;
}

.first-letter\:bk-uppercase::first-letter {
  text-transform: uppercase;
}

.placeholder\:bk-text-gray-900::-moz-placeholder {
  --tw-text-opacity: 1;
  color: rgb(17 25 40 / var(--tw-text-opacity));
}

.placeholder\:bk-text-gray-900::placeholder {
  --tw-text-opacity: 1;
  color: rgb(17 25 40 / var(--tw-text-opacity));
}

.after\:bk-absolute::after {
  content: var(--tw-content);
  position: absolute;
}

.after\:bk-left-\[2px\]::after {
  content: var(--tw-content);
  left: 2px;
}

.after\:bk-top-\[2px\]::after {
  content: var(--tw-content);
  top: 2px;
}

.after\:bk-h-4::after {
  content: var(--tw-content);
  height: 1rem;
}

.after\:bk-h-5::after {
  content: var(--tw-content);
  height: 1.25rem;
}

.after\:bk-w-4::after {
  content: var(--tw-content);
  width: 1rem;
}

.after\:bk-w-5::after {
  content: var(--tw-content);
  width: 1.25rem;
}

.after\:bk-translate-x-0::after {
  content: var(--tw-content);
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.after\:bk-translate-x-\[16px\]::after {
  content: var(--tw-content);
  --tw-translate-x: 16px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.after\:bk-translate-x-\[20px\]::after {
  content: var(--tw-content);
  --tw-translate-x: 20px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.after\:bk-rounded-full::after {
  content: var(--tw-content);
  border-radius: 9999px;
}

.after\:bk-bg-white::after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.after\:bk-transition-transform::after {
  content: var(--tw-content);
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.after\:bk-content-\[\"\"\]::after {
  --tw-content: "";
  content: var(--tw-content);
}

.first\:\!bk-rounded-l-lg:first-child {
  border-top-left-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}

.first\:bk-border-l:first-child {
  border-left-width: 1px;
}

.last\:\!bk-rounded-r-lg:last-child {
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}

.last\:\!bk-border-r-gray-200:last-child {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(229 231 235 / var(--tw-border-opacity)) !important;
}

.hover\:bk-cursor-pointer:hover {
  cursor: pointer;
}

.hover\:bk-border-\[--tw-ring-color\]:hover {
  border-color: var(--tw-ring-color);
}

.hover\:bk-border-blue-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(26 86 219 / var(--tw-border-opacity));
}

.hover\:\!bk-bg-brand-100:hover {
  background-color: var(--brand-100) !important;
}

.hover\:\!bk-bg-brand-800:hover {
  background-color: var(--brand-800) !important;
}

.hover\:bk-bg-blue-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(235 245 255 / var(--tw-bg-opacity));
}

.hover\:bk-bg-brand-50:hover {
  background-color: var(--brand-50);
}

.hover\:bk-bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.hover\:bk-bg-gray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.hover\:bk-bg-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.hover\:bk-bg-primary-50:hover {
  background-color: var(--primary-50);
}

.hover\:\!bk-text-white:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.hover\:bk-text-brand-800:hover {
  color: var(--brand-800);
}

.hover\:bk-text-primary-700:hover {
  color: var(--primary-700);
}

.hover\:bk-opacity-90:hover {
  opacity: 0.9;
}

.hover\:bk-opacity-\[0\.15\]:hover {
  opacity: 0.15;
}

.hover\:bk-ring:hover {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.hover\:bk-ring-2:hover {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.hover\:bk-ring-gray-100:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(243 244 246 / var(--tw-ring-opacity));
}

.focus\:bk-border-2:focus {
  border-width: 2px;
}

.focus\:bk-border-solid:focus {
  border-style: solid;
}

.focus\:bk-border-\[--tw-ring-color\]:focus {
  border-color: var(--tw-ring-color);
}

.focus\:bk-border-blue-200:focus {
  --tw-border-opacity: 1;
  border-color: rgb(195 221 253 / var(--tw-border-opacity));
}

.focus\:bk-border-brand-600:focus {
  border-color: var(--brand-600);
}

.focus\:bk-bg-gray-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.focus\:bk-bg-gray-50:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.focus\:bk-bg-opacity-80:focus {
  --tw-bg-opacity: 0.8;
}

.focus\:bk-text-brand-800:focus {
  color: var(--brand-800);
}

.focus\:bk-outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.focus\:bk-outline-brand-600:focus {
  outline-color: var(--brand-600);
}

.focus\:bk-ring-transparent:focus {
  --tw-ring-color: transparent;
}

.bk-group\/item:hover .group-hover\/item\:bk-hidden {
  display: none;
}

.bk-group\/item:hover .group-hover\/item\:bk-rounded-lg {
  border-radius: 0.5rem;
}

.bk-group\/item:hover .group-hover\/item\:bk-bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.bk-group\/item:hover .group-hover\/item\:bk-text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 25 40 / var(--tw-text-opacity));
}

.bk-group:hover .group-hover\:bk-text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 25 40 / var(--tw-text-opacity));
}

.bk-group\/item:hover .group-hover\/item\:bk-opacity-100 {
  opacity: 1;
}

.bk-group:hover .group-hover\:bk-opacity-100 {
  opacity: 1;
}

.bk-group\/item:hover .group-hover\/item\:bk-ring-\[7px\] {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(7px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.bk-group\/item:hover .group-hover\/item\:bk-ring-gray-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(243 244 246 / var(--tw-ring-opacity));
}

.bk-group:focus .group-focus\:bk-text-primary-700 {
  color: var(--primary-700);
}

.bk-group:focus .group-focus\:bk-ring-2 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.bk-group:focus .group-focus\:bk-ring-4 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.bk-group:focus .group-focus\:bk-ring-brand-200 {
  --tw-ring-color: var(--brand-200);
}

.bk-group:focus .group-focus\:bk-ring-brand-500 {
  --tw-ring-color: var(--brand-500);
}

.bk-group:focus .group-focus\:bk-ring-primary-500 {
  --tw-ring-color: var(--primary-500);
}

@media (max-width: 576px) {
  .sm\:bk-block {
    display: block;
  }

  .sm\:bk-hidden {
    display: none;
  }

  .sm\:bk-w-\[300px\] {
    width: 300px;
  }

  .sm\:bk-w-\[376px\] {
    width: 376px;
  }

  .sm\:bk-w-full {
    width: 100%;
  }

  .sm\:bk-px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .sm\:bk-py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
}

@media (max-width: 768px) {
  .md\:\!bk-fixed {
    position: fixed !important;
  }

  .md\:bk-fixed {
    position: fixed;
  }

  .md\:bk-relative {
    position: relative;
  }

  .md\:bk-bottom-0 {
    bottom: 0px;
  }

  .md\:bk-bottom-4 {
    bottom: 1rem;
  }

  .md\:bk-left-0 {
    left: 0px;
  }

  .md\:bk-right-0 {
    right: 0px;
  }

  .md\:bk-top-0 {
    top: 0px;
  }

  .md\:bk-top-full {
    top: 100%;
  }

  .md\:bk-m-0 {
    margin: 0px;
  }

  .md\:\!bk-block {
    display: block !important;
  }

  .md\:bk-block {
    display: block;
  }

  .md\:\!bk-flex {
    display: flex !important;
  }

  .md\:\!bk-hidden {
    display: none !important;
  }

  .md\:bk-hidden {
    display: none;
  }

  .md\:bk-h-full {
    height: 100%;
  }

  .md\:bk-max-h-\[calc\(100\%-32px\)\] {
    max-height: calc(100% - 32px);
  }

  .md\:bk-w-full {
    width: 100%;
  }

  .md\:bk-w-screen {
    width: 100vw;
  }

  .md\:bk-max-w-\[calc\(100vw-32px\)\] {
    max-width: calc(100vw - 32px);
  }

  .md\:-bk-translate-x-full {
    --tw-translate-x: -100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:\!bk-flex-col {
    flex-direction: column !important;
  }

  .md\:bk-flex-col {
    flex-direction: column;
  }

  .md\:\!bk-justify-center {
    justify-content: center !important;
  }

  .md\:\!bk-gap-0 {
    gap: 0px !important;
  }

  .md\:bk-self-end {
    align-self: flex-end;
  }

  .md\:bk-rounded-b-none {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .md\:bk-bg-opacity-50 {
    --tw-bg-opacity: 0.5;
  }

  .md\:bk-px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .md\:bk-pb-0 {
    padding-bottom: 0px;
  }

  .md\:bk-pt-4 {
    padding-top: 1rem;
  }
}

.\[\&\>\*\]\:\!bk-rounded-full>* {
  border-radius: 9999px !important;
}