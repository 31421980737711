@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .g-login span {
    @apply hidden;
  }
  .g-login.loading {
    @apply brightness-90;
  }
  .g-login.loading span {
    @apply inline;
  }
}

@layer utilities {
  /* Hide the Scrollbar */
  .hide-scrollbar {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
  }
  .hide-scrollbar::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  .hide-icon::-webkit-calendar-picker-indicator {
    background: none;
  }
}

/* remove default border when prefilght setting turned off (https://github.com/tailwindlabs/tailwindcss/issues/289#issuecomment-860280393) */
* {
  border-width: 0;
  border-style: solid;
}

/* for the global Modal component exit & enter animation */
.modal-enter {
  opacity: 0;
  transform: translate3d(0, -50%, 0);
}
.modal-enter-active {
  opacity: 1;
  transform: translate3d(0, 0%, 0);
  transition: all 360ms ease-in-out;
}
.modal-exit {
  opacity: 1;
  transform: translate3d(0, 0%, 0);
}
.modal-exit-active {
  opacity: 0;
  transform: translate3d(0, -50%, 0);
  transition: all 360ms ease-in-out;
}

/* Partially reset styles (add ".reset-styles" className to where you want) */
.reset-styles a,
.reset-styles abbr,
.reset-styles acronym,
.reset-styles address,
.reset-styles applet,
.reset-styles article,
.reset-styles aside,
.reset-styles audio,
.reset-styles b,
.reset-styles big,
.reset-styles blockquote,
.reset-styles body,
.reset-styles canvas,
.reset-styles caption,
.reset-styles center,
.reset-styles cite,
.reset-styles code,
.reset-styles dd,
.reset-styles del,
.reset-styles details,
.reset-styles dfn,
.reset-styles div,
.reset-styles dl,
.reset-styles dt,
.reset-styles em,
.reset-styles embed,
.reset-styles fieldset,
.reset-styles figcaption,
.reset-styles figure,
.reset-styles footer,
.reset-styles form,
.reset-styles h1,
.reset-styles h2,
.reset-styles h3,
.reset-styles h4,
.reset-styles h5,
.reset-styles h6,
.reset-styles header,
.reset-styles hgroup,
.reset-styles html,
.reset-styles i,
.reset-styles iframe,
.reset-styles img,
.reset-styles ins,
.reset-styles kbd,
.reset-styles label,
.reset-styles legend,
.reset-styles li,
.reset-styles mark,
.reset-styles menu,
.reset-styles nav,
.reset-styles object,
.reset-styles ol,
.reset-styles output,
.reset-styles p,
.reset-styles pre,
.reset-styles q,
.reset-styles ruby,
.reset-styles s,
.reset-styles samp,
.reset-styles section,
.reset-styles small,
.reset-styles span,
.reset-styles strike,
.reset-styles strong,
.reset-styles sub,
.reset-styles summary,
.reset-styles sup,
.reset-styles table,
.reset-styles tbody,
.reset-styles td,
.reset-styles tfoot,
.reset-styles th,
.reset-styles thead,
.reset-styles time,
.reset-styles tr,
.reset-styles tt,
.reset-styles u,
.reset-styles ul,
.reset-styles var,
.reset-styles video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  color: initial;
}
.reset-styles button,
.reset-styles input[type='button'],
.reset-styles input[type='file'],
.reset-styles input[type='reset'],
.reset-styles input[type='submit'] {
  border-radius: 0;
}
.reset-styles input[type='text']::-ms-clear {
  display: none;
}
.reset-styles article,
.reset-styles aside,
.reset-styles details,
.reset-styles figcaption,
.reset-styles figure,
.reset-styles footer,
.reset-styles header,
.reset-styles hgroup,
.reset-styles menu,
.reset-styles nav,
.reset-styles section {
  display: block;
}
.reset-styles body {
  line-height: 1;
}
.reset-styles sup {
  vertical-align: super;
}
.reset-styles sub {
  vertical-align: sub;
}
.reset-styles blockquote,
.reset-styles q {
  quotes: none;
}
.reset-styles blockquote:after,
.reset-styles blockquote:before,
.reset-styles q:after,
.reset-styles q:before {
  content: '';
  content: none;
}
.reset-styles table {
  border-collapse: collapse;
  border-spacing: 0;
}
.reset-styles * {
  box-sizing: border-box;
}

ul.framer-text > li {
  display: table-row;
  counter-increment: list-item 1;
  list-style: none;
}

ul.framer-text > li::before {
  display: table-cell;
  width: 2.25ch;
  box-sizing: border-box;
  padding-right: 0.75ch;
  content: '•';
}

@media (max-width: 767px) {
  input {
    font-size: 16px !important;
  }
}

.modal-capacitor {
  margin-top: 55px;
}

@media (max-width: 768px) {
  .intercom-messenger-launcher {
    /* value 65 is from bottom navigation's height (54) + added some padding, 20 is the default value of intercom */
    bottom: 65px !important;
  }
}

.intercom-messenger-launcher-footer-padding {
  /* E-sign footer height padding */
  bottom: 90px !important;
}

/* Add Employee Modal Mobile Styles (make height fill to fit parent) */
@media (max-width: 768px) {
  .add-employee-modal div[data-framer-name='ModalWrap'],
  .add-employee-modal div[data-framer-name='Modal'],
  .add-employee-modal div[data-framer-name*='Form'],
  .add-employee-modal form,
  .add-employee-modal div[data-framer-name*='Search Address'] {
    flex: 1 0 0px;
    height: 100%;
    max-height: initial;
  }
  .add-employee-modal div[data-framer-name*='Inputs'] {
    place-content: space-between;
  }
}

/* For the Ticket PROD-3575 - [FE] Global App -  Employee Flow Update */
/* https://app.clickup.com/t/6912544/PROD-3575 */
.tsr-body
  #react-modal
  > div
  > div
  > div
  > div.framer-smjv13
  > div.framer-1cii6o3
  > div.framer-444ggg-container
  > form
  > div
  > div.framer-ixtr5j
  > div.framer-q8xpjo-container {
  display: none;
}

.tsr-body
  #react-modal
  > div
  > div
  > div
  > div.framer-smjv13
  > div.framer-1cii6o3
  > div.framer-444ggg-container
  > form
  > div
  > div.framer-ixtr5j
  > div.framer-1862sg4 {
  display: none;
}

.tsr-body
  #react-modal
  > div
  > div
  > div
  > div.framer-smjv13
  > div.framer-1cii6o3
  > div.framer-444ggg-container
  > form
  > div
  > div.framer-ixtr5j
  > div.framer-u6sq9n-container {
  display: none;
}

.tsr-body
  #react-modal
  > div
  > div
  > div
  > div.framer-smjv13
  > div.framer-1cii6o3
  > div.framer-444ggg-container
  > form
  > div
  > div.framer-ixtr5j
  > div.framer-jfupje {
  display: none;
}

/* hide Leave Loading */
.tsr-body
  #react-modal
  > div
  > div
  > div
  > div.framer-smjv13
  > div.framer-1cii6o3
  > div.framer-1vbnbu3-container
  > form
  > div
  > div.framer-x1hfx4
  > div.framer-56c0kv
  > div.framer-ibr2gc {
  display: none;
}
.tsr-body
  #react-modal
  > div
  > div
  > div
  > div.framer-smjv13
  > div.framer-1cii6o3
  > div.framer-1vbnbu3-container
  > form
  > div
  > div.framer-x1hfx4
  > div.framer-1b0y236
  > p {
  display: none;
}

/* hide edit employee - Address */
.tsr-body #edit-employee-address {
  display: none;
}

/* hide change status button */
.tsr-body #changeStatusBtn {
  display: none;
}

/* hide ato related text  */
.tsr-body span.newEmpATOtext {
  display: none;
}

/**
 * Fix override flowbite issues
 */

.form-control[type='number'],
.form-control[type='text'],
.form-control[type='phone'] {
  box-shadow: none !important;
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 0.9rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid #dde2ec;
  border-radius: 4px;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.form-control[type='number']:focus,
.form-control[type='text']:focus,
.form-control[type='phone']:focus {
  border: 2px solid #0E9F6E !important;
}


.form-control[type='checkbox'],
.form-control[type='radio'] {
  box-shadow: none !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  flex-shrink: 0;
  height: 1rem;
  width: 1rem;
  color: #1c64f2;
  background-color: #fff;
  border-color: #6b7280;
  border-width: 1px;
  --tw-shadow: 0 0 #0000;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form-control[type='checkbox'] {
  border-radius: 0px;
}

.form-control[type='radio'] {
  border-radius: 100%;
}

.form-control[type='checkbox']:focus,
.form-control[type='radio']:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #1c64f2;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
}

.form-control[type='checkbox']:checked,
[type='radio']:checked,
.dark [type='checkbox']:checked,
.dark [type='radio']:checked {
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.form-control[type='checkbox']:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

.form-control[type='radio']:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

.form-control[type='checkbox']:indeterminate {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.form-control[type='checkbox']:indeterminate:hover,
[type='checkbox']:indeterminate:focus {
  border-color: transparent;
  background-color: currentColor;
}

/* Hide clear button on Refer & Earn modal */
.framer-upgpac-container {
  display: none;
}
